import React from 'react';
import { useIntl } from "gatsby-plugin-react-intl"

export default function CarouselSlide(props) {
    const { title } = props.content;
    const intl = useIntl()

    return (
        <div style={{width:'70vw', padding: '75px 50px', margin: '0px 25px',display: 'flex',justifyContent: 'center'}}>
            <h3 className="font">
                {intl.formatMessage({ id: title }, { title: intl.formatMessage({ id: 'title' }) })}
            </h3>
        </div>
    );
}